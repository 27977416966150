(function () {
  'use strict';

  angular
  .module('neo.home.refereeNewInvoice.invoicesgroup')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.invoicesgroup', {
      url: '/referee-new-invoice/invoicesgroup',
     
          templateUrl: 'home/referee-new-invoice/invoices-group/invoices-group.tpl.html',
          controller: 'invoicesgroup',
          controllerAs: 'vm',
        resolve:{
          townHalls: function (Ajuntaments) {
            return Ajuntaments.query().$promise;
          }
        }
       
    }).
      state('home.invoiceref',{
          url: '/referee-new-invoice/invoice/{personId}/{numinvoice}/{anyo}',
          templateUrl: 'home/referee-new-invoice/invoices-group/invoice.html',
          controller: 'invoiceprint',
          controllesAs:'vm',
          resolve:{
              personId:function ( $stateParams){
          return  $stateParams.personId;
    },
             numinvoice:function ( $stateParams){
          return $stateParams.numinvoice;
    },
             anyo:function ( $stateParams){
          return $stateParams.anyo;
    },
             personafact:function (RefereeLicence,$stateParams){
           return RefereeLicence.get({licenceId:$stateParams.personId}).$promise;
    },
             lineesfact:function ( InvoiceLines,$stateParams){
           return InvoiceLines.query({Id:$stateParams.personId, invoiceid:$stateParams.numinvoice,anyo:$stateParams.anyo}).$promise;
    },
    Receptores: function(receptores,$stateParams){
        return receptores.query();}
          }
    });
  }
}());


